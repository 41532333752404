<script lang="ts" setup>
const { t } = useT();

defineProps<{
	title: string;
	route?: string;
}>();

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};
</script>

<template>
	<div class="header">
		<div class="content-left">
			<AText type="h8 h5-md" :data-tid="`slider-title-${getTitle(title)}`">
				{{ title }}
			</AText>
			<slot name="content-left" />
		</div>
		<div class="content-right">
			<NuxtLink v-if="route" :to="route">
				<AText type="base" :data-tid="`slider-${getTitle(title)}-all`" :modifiers="['link', 'underline']">
					{{ t("View all") }}
				</AText>
			</NuxtLink>
			<slot name="content-right" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	width: 100%;
	min-height: 30px;
}

.content-left {
	display: flex;
	align-items: center;
	gap: 8px;
}

.content-right {
	display: flex;
	gap: 8px;

	.link:hover {
		text-decoration: inherit;
	}
}
</style>
